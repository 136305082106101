<template>
    <div class="tit-wrap--small">
        <div class="pb-16px pb-md-20px">
            <v-row align="center" class="row--sm">
                <div class="col col-auto">
                    <div v-if="this.$slots['titleContents']" class="tit tit--xs">
                        <slot name="titleContents" />
                    </div>
                    <div v-if="titleText" class="divider-group divider-group--lg">
                        <ul class="grey--text text--darken-4">
                            <li>
                                <h3 class="tit tit--xs">{{ title }}</h3>
                            </li>
                            <li>
                                <div class="txt txt--sm txt--dark">{{ titleText }}</div>
                            </li>
                        </ul>
                    </div>
                    <h3 v-else class="tit tit--xs">{{ title }}</h3>
                </div>
                <v-spacer v-if="this.$slots['default']" />
                <div v-if="this.$slots['default']" class="col col-auto">
                    <slot />
                </div>
            </v-row>
        </div>
    </div>
</template>

<script>
export default {
    props: {
        title: { type: String },
        titleText: { type: String },
    },
    components: {},
    data: () => {
        return {};
    },
    mounted() {},
    methods: {},
};
</script>

<style lang="scss" scoped>
.divider-group {
    > ul {
        > li {
            &::before {
                background-color: var(--v-grey-lighten3);
            }
        }
    }
}
</style>
