var render = function render(){
  var _vm$storeUserTypeText;
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('v-dialog', _vm._b({
    attrs: {
      "name": "modal",
      "scrollable": ""
    },
    scopedSlots: _vm._u([{
      key: "activator",
      fn: function (_ref) {
        var attrs = _ref.attrs,
          on = _ref.on;
        return [_vm._t("activator", null, null, {
          attrs,
          on,
          userTypeMatches: _vm.userTypeMatches
        })];
      }
    }], null, true),
    model: {
      value: _vm.shows,
      callback: function ($$v) {
        _vm.shows = $$v;
      },
      expression: "shows"
    }
  }, 'v-dialog', Object.assign({}, _vm.$attrs, {
    width: _vm.width,
    maxWidth: _vm.maxWidth
  }), false), [_c('v-card', {
    attrs: {
      "tile": ""
    }
  }, [_c('v-toolbar', [_c('v-toolbar-title', {
    staticClass: "d-flex align-center"
  }, [this.$slots['titleContents'] ? [_vm._t("titleContents")] : _c('span', [_vm._v(_vm._s(_vm.title))])], 2), _c('v-spacer'), _c('v-btn', {
    staticClass: "transparent",
    attrs: {
      "icon": ""
    },
    on: {
      "click": _vm.close
    }
  }, [_c('v-icon', [_vm._v("mdi-close")])], 1)], 1), _c('v-card-text', {
    staticClass: "pa-12px px-md-20px py-md-24px"
  }, [_vm.showsContents ? [_vm._t("default")] : !_vm.userHasLoggedIn ? [_c('v-layout', {
    attrs: {
      "fill-height": "",
      "column": "",
      "justify-center": "",
      "align-center": ""
    }
  }, [_c('v-sheet', {
    attrs: {
      "width": "400",
      "max-width": "100%"
    }
  }, [_c('login-card')], 1)], 1)] : _c('temlpate', [_c('v-layout', {
    attrs: {
      "fill-height": "",
      "column": "",
      "justify-center": "",
      "align-center": ""
    }
  }, [_c('v-sheet', {
    attrs: {
      "width": "400",
      "max-width": "100%"
    }
  }, [_c('v-responsive', {
    attrs: {
      "aspect-ratio": 4 / 3
    }
  }, [_c('v-sheet', {
    staticClass: "d-flex flex-column justify-center text-center fill-height"
  }, [_c('span', {
    staticClass: "font-size-20"
  }, [_c('b', [_vm._v(" " + _vm._s((_vm$storeUserTypeText = _vm.storeUserTypeText) !== null && _vm$storeUserTypeText !== void 0 ? _vm$storeUserTypeText : "관리자") + " ")]), _vm._v(" 사용자 유형으로는 "), _c('br'), _vm._v(" 사용할 수 없는 기능입니다. ")])])], 1)], 1)], 1)], 1)], 2), _vm.$slots['actions'] ? _c('v-card-actions', {
    staticClass: "pa-12px px-md-20px py-md-24px border-t"
  }, [_vm._t("actions")], 2) : _vm._e()], 1)], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }