<template>
    <v-sheet :color="sheetColor" class="d-flex flex-column" style="flex: 1 0 auto">
        <v-row no-gutters align="center" class="txt txt--sm" :justify="justifySpaceBetween ? 'space-between' : undefined">
            <v-col cols="12" md="auto" v-bind="$attrs.headerProps">
                <div class="w-md-140px txt--dark font-weight-bold pt-16px pb-4px px-10px px-md-20px py-md-16px break-keep text-pre-wrap">{{ term }} <span v-if="required" class="red--text">*</span></div>
            </v-col>
            <v-col cols="12" :md="justifySpaceBetween ? 'auto' : ''" style="white-space: pre-line">
                <div class="pt-4px pb-16px px-10px px-md-20px py-md-16px">
                    <slot />
                </div>
            </v-col>
        </v-row>
        <v-divider v-if="!noDevider" />
    </v-sheet>
</template>

<script>
export default {
    props: {
        term: String,
        required: Boolean,
        noDevider: Boolean,
        justifySpaceBetween: Boolean,
    },
    computed: {
        sheetColor() {
            if(this.term === "배우자 정보") {
                return "grey lighten-5"
            }
        }
    },
};
</script>

<style lang="scss" scoped>
.text-pre-wrap {
    white-space: pre-wrap;
}
</style>
