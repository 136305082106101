<template>
    <u-dialog v-model="shows" title="개인정보 수집 및 이용" persistent width="600">
        <template #activator="{ attrs, on }">
            <slot name="activator" v-bind="{ attrs, on, isValid }" />
        </template>
        <div v-if="isLoaded">
            <!-- <template v-for="term in termsList"> -->
            <div v-for="term in termsList" :key="term._id">
                <tit-wrap-small>
                    <template #titleContents>
                        {{ term.subject }}에 동의<span class="pl-4px" :class="[term.necessary ? 'red--text' : 'grey--text text--lighten-1']">({{ term.necessary ? "필수" : "선택" }})</span>
                    </template>
                    <v-checkbox v-model="selected" :value="term._id" label="약관동의" />
                </tit-wrap-small>
                <v-card tile outlined rounded="md">
                    <div class="pa-16px pa-md-20px mh-120px" style="overflow-y: auto"><div v-html="term.content.replace(/\n/gi, '<br/>')" /></div>
                </v-card>
                <!-- <template #pageSectionFoot>
                        <v-card outlined tile>
                            <div class="pa-16px pa-md-20px mh-120px" style="overflow-y: auto;"><div v-html="term.content.replace(/\n/gi, '<br/>')" /></div>
                        </v-card>
                    </template> -->
            </div>
            <!-- </template> -->

            <div class="btn-wrap">
                <v-row class="row--sm">
                    <v-col>
                        <v-btn tile x-large block outlined color="grey" @click="cancel">취소</v-btn>
                    </v-col>
                    <v-col>
                        <v-btn tile x-large block color="grey darken-4" @click="agree"><span class="white--text">동의</span></v-btn>
                    </v-col>
                </v-row>
            </div>
        </div>
    </u-dialog>
</template>

<script>
import api from "@/api";
import { attrs_input } from "@/assets/variables";
import UDialog from "@/components/dumb/u-dialog.vue";
import TitWrapSmall from "@/components/dumb/tit-wrap-small.vue";
import PageSection from "@/sets/styles/pages/page-section.vue";

export default {
    components: {
        UDialog,
        TitWrapSmall,
        PageSection,
    },
    props: {
        value: { type: Array, default: () => [] },
        params: { type: Object, default: () => ({}) },
    },
    data: () => ({
        shows: false,
        isLoaded: false,
        selected: [],
        termsList: [],

        attrs_input,
    }),
    computed: {
        isValid() {
            let necessaryList = this.termsList.filter((term) => term.necessary);
            return !necessaryList.some(({ _id }) => !this.selected.includes(_id));
        },
    },
    created() {
        this.init();
    },
    mounted() {
        this.selected = this.value;
    },
    watch: {
        shows() {
            if (this.shows) this.init();
        },

        isLoaded(isLoaded) {
            if (isLoaded) this.$emit("isLoaded", this.termsList);
        },
    },
    methods: {
        async init() {
            try {
                this.isLoaded = false;

                let { params } = this;
                var { termsList } = await api.v1.terms.gets({ params });
                this.termsList = termsList;

                this.isLoaded = true;
            } catch (error) {
                this.$handleError(error);
            }
        },

        toggleSelectedAll() {
            if (this.isValid) this.selected = [];
            else this.selected = this.termsList.map(({ _id }) => _id);
        },
        cancel() {
            this.selected = this.value;
            this.shows = false;
        },
        agree() {
            this.$emit("input", this.selected);
            this.$emit("isValid", this.isValid);
            this.shows = false;
        },
    },
};
</script>

<style></style>
