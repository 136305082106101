<template>
    <terms-dialog v-model="selected" v-bind="{ params }" @isLoaded="setTermsList" @input="(value) => $emit('input', value)" @isValid="(isValid) => $emit('isValid', isValid)">
        <template #activator="{ on, isValid }">
            <div v-on="on" class="cursor-pointer">
                <v-card outlined tile>
                    <v-layout justify-center>
                        <div class="px-16px py-8px px-md-20px">
                            <!-- <template v-for="terms in termsList"> -->
                                <v-checkbox  v-for="terms in termsList" :key="terms._id" :value="selected.includes(terms._id)" hide-details color="primary" readonly class="my-8px my-md-12px">
                                    <template #label>
                                        <v-row no-gutters justify="space-between">
                                            <v-col cols="auto">
                                                {{ terms.subject }}에 동의합니다.
                                                <span class="pl-4px" :class="[terms.necessary ? 'pink--text' : 'grey--text text--lighten-1']">({{ terms.necessary ? "필수" : "선택" }})</span>
                                            </v-col>
                                            <!-- <v-col cols="auto" class="d-none d-md-block">
                                                <span class="grey--text text--lighten-1">내용보기</span>
                                            </v-col> -->
                                        </v-row>
                                    </template>
                                </v-checkbox>
                            <!-- </template> -->
                        </div>
                    </v-layout>
                </v-card>
            </div>
        </template>
    </terms-dialog>
</template>

<script>
import TermsDialog from "./terms-dialog.vue";

export default {
    components: {
        TermsDialog,
    },
    props: {
        value: { type: Array, default: () => [] },
        params: { type: Object, default: () => ({}) },
    },
    data: () => ({
        termsList: [],
        selected: [],
    }),
    methods: {
        setTermsList(termsList) {
            this.termsList = termsList;
        },
    },
};
</script>

<style></style>
